const key = "mk2sl3nm4op577e2";
import CryptoJS from 'crypto-js'

export const cript = {
encryptData: (data) => {
    return CryptoJS.AES.encrypt(data, key).toString();
  },

decryptData: (data) => {
    let bytes = CryptoJS.AES.decrypt(data, key)
    return bytes.toString(CryptoJS.enc.Utf8);
}
}