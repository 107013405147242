import axios from 'axios'
import router from '@/router';
import store from '../store/store'



const api = axios.create({ 
    baseURL: process.env.VUE_APP_API,
});
api.interceptors.response.use((response) => { 
      return response  
}, (error) => { 
    if (error.response.status == 401){
        store.dispatch('logout')
        this.clearCache();
        router.push("/login");
    }
});

export default api