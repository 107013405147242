<template>
   <v-app>
    <nav-bar v-if="!$route.meta.hideNavbar"  />
    <vue-snotify></vue-snotify>
    <v-main>
    <router-view/>
    </v-main>
   </v-app>
</template>
<script>
import NavBar from "./components/layout/NavBar.vue";
export default {
  components: { NavBar },

  name: "App",
  created() {},
};

</script>
<style>
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  padding: -100px !important;
  margin: -100px !important;
}
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

</style>
