import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'
Vue.use(Vuex)
Vue.use(VueCookies)


export default new Vuex.Store({
    state: {
      token: null,
      credenciais: null,
      user: null
    },
    mutations: {
      //token
      setToken(state, token) {
        state.token = token
      },
      deleteToken(state){
        state.token = null
      },
      //user 
      setUser(state, user){
        state.user = user
      },
      deleteUser(state){
        state.user = null
      },
     
      //credenciais
      setCredenciais(state, credenciais){
        state.credenciais = credenciais
      },
      deleteCredenciais(state){
        state.credenciais = null
      },

    },
    actions: {
      saveToken({ commit }, token) {
        commit('setToken', token)
        Vue.$cookies.set('token', token);
      },
      logout({ commit }) {
        commit('deleteToken')
        commit('deleteUser');
        Vue.$cookies.remove('user')
        Vue.$cookies.remove('token')
      },
      saveCredenciais({ commit }, credenciais) {
        commit('setCredenciais', credenciais)
        Vue.$cookies.set('credenciais', credenciais);
      },
      deleteCredenciais({ commit }) {
        commit('deleteCredenciais')
        Vue.$cookies.remove('credenciais');
      },
      saveUser({ commit }, user) {
        commit('setUser', user)
        Vue.$cookies.set('user', user);
      },
    },
    getters: {
      isAuthenticated: state => !!state.token
    }
  })
  

  