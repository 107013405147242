import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import "vue-snotify/styles/material.css";
import Snotify, {SnotifyPosition} from 'vue-snotify';
import {func} from '@/functions/functions.js';
import {cript} from '@/functions/criptografia.js';
import store from '@/store/store'
import { VueMaskDirective } from 'v-mask'
import "@/plugins/vuetify-mask.js";

Vue.config.productionTip = false;
const Snotifyoptions = {
  toast: {
    position: SnotifyPosition.rightTop
  }
}
Vue.directive('mask', VueMaskDirective);
Vue.use(Snotify , Snotifyoptions);
Vue.prototype.$func = func;
Vue.prototype.$cript = cript;
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
