import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import store from '@/store/store'
import api from '@/config/api'
import { cript } from '@/functions/criptografia'


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login  ',
    component: () => import('../views/authentication/LoginForm.vue'),
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
    },
    
    component: () => import('../views/dashboard/DashboardView.vue'),
    
  },
  //clientes
  {
    path: '/cliente',
    name: 'IndexCustomer',
    meta: {
      title: "Clientes",
      requiresAuth: true,
    },
    component: () => import('../views/customers/IndexCustomer.vue'),
  },
  {
    path: '/cliente/inserir',
    name: 'InsertCustomer',
    meta: {
      title: "Cadastro Cliente",
      requiresAuth: true,
    },
    component: () => import('../views/customers/InsertCustomer.vue'),
  },
  {
    path: '/cliente/alterar',
    name: 'AlterCustomer',
    meta: {
      title: "Edição Cliente",
      requiresAuth: true,
    },
    component: () => import('../views/customers/AlterCustomer.vue'),
  },
  //obras
  {
    path: '/obras',
    name: 'IndexObras',
    meta: {
      title: "Obras",
      requiresAuth: true,
    },
    component: () => import('../views/obras/IndexObras.vue'),
  },
  {
    path: '/obras/inserir',
    name: 'InsertObra',
    meta: {
      title: "Cadastro Obra",
      requiresAuth: true,
    },
    component: () => import('../views/obras/InsertObras.vue'),
  },
  {
    path: '/obras/alterar',
    name: 'AlterObras',
    meta: {
      title: "Alterar Obra",
      requiresAuth: true,
    },
    component: () => import('../views/obras/AlterObras.vue'),
  },
  //bens
  {
    path: '/bens',
    name: 'IndexBens',
    meta: {
      title: "Bens",
      requiresAuth: true,
    },
    component: () => import('../views/bens/IndexBens.vue'),
  },
  {
    path: '/bens/inserir',
    name: 'InsertBens',
    meta: {
      title: "Novo Bem",
      requiresAuth: true,
    },
    component: () => import('../views/bens/InsertBens.vue'),
  },
  {
    path: '/bens/alterar',
    name: 'AlterBens',
    meta: {
      title: "Alterar Bem",
      requiresAuth: true,
    },
    component: () => import('../views/bens/AlterBens.vue'),
  },
  //contratos
  {
    path: '/contratos',
    name: 'IndexContratos',
    meta: {
      title: "Contratos",
      requiresAuth: true,
    },
    component: () => import('../views/contratos/IndexContratos.vue'),
  },
  {
    path: '/contratos/inserir',
    name: 'InsertContratos',
    meta: {
      title: "Novo Contrato",
      requiresAuth: true,
    },
    component: () => import('../views/contratos/InsertContratos.vue'),
  },
  {
    path: '/contratos/alterar',
    name: 'AlterContratos',
    meta: {
      title: "Alterar Contrato",
      requiresAuth: true,
    },
    component: () => import('../views/contratos/AlterContratos.vue'),
  },
  //usuarios
  {
    path: '/usuarios',
    name: 'IndexUsuarios',
    meta: {
      title: "Usuários",
      requiresAuth: true,
    },
    component: () => import('../views/usuarios/IndexUsuarios.vue'),
  },
  {
    path: '/usuarios/inserir',
    name: 'InsertUsuarios',
    meta: {
      title: "Cadastro Usuário",
      requiresAuth: true,
    },
    component: () => import('../views/usuarios/InsertUsuarios.vue'),
  },
  {
    path: '/usuarios/alterar',
    name: 'AlterUsuarios',
    meta: {
      title: "Alterar Usuário",
      requiresAuth: true,
    },
    component: () => import('../views/usuarios/AlterUsuarios.vue'),
  },
  //INCC
  {
    path: '/incc',
    name: 'IndexINCC',
    meta: {
      title: "Incc",
      requiresAuth: true,
    },
    component: () => import('../views/incc/IndexIncc.vue'),
  },
  {
    path: '/incc/inserir',
    name: 'InsertIncc',
    meta: {
      title: "Incc",
      requiresAuth: true,
    },
    component: () => import('../views/incc/InsertIncc.vue'),
  },
  {
    path: '/incc/alterar',
    name: 'AlterIncc',
    meta: {
      title: "Incc",
      requiresAuth: true,
    },
    component: () => import('../views/incc/AlterIncc.vue'),
  },
  {
    path: '/pagamentos',
    name: 'IndexCobrancas',
    meta: {
      title: "Cobranças",
      requiresAuth: true,
    },
    component: () => import('../views/cobrancas/IndexCobrancas.vue'),
  },
  {
    path: '/pagamentos/alterar',
    name: 'AlterCobrancas',
    meta: {
      title: "Gerenciar Cobrança",
      requiresAuth: true,
    },
    component: () => import('../views/cobrancas/AlterCobrancas.vue'),
  },
  {
    path: '/pagamentos/mesclar',
    name: 'MesclarCobrancas',
    meta: {
      title: "Mesclar Cobranças",
      requiresAuth: true,
    },
    component: () => import('../views/cobrancas/MesclarCobrancas.vue'),
  },
  {
    path: '/beneficiario',
    name: 'DadosBeneficiario',
    meta: {
      title: "Dados Beneficiário",
      requiresAuth: true,
    },
    component: () => import('../views/beneficiario/IndexBeneficiario.vue'),
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  let token = "";
  if (VueCookies.get('token')) {
    store.dispatch('saveToken', VueCookies.get('token'));
    token = cript.decryptData(store.state.token);
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // Verifica se o usuário está autenticado usando o getter 'isAuthenticated'
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router
