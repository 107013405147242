import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
const env = require('@/config/env.json');


Vue.use(Vuetify);

export default new Vuetify({
  

  theme: {
    themes: {
      light: env.colors
    }
  }

});
