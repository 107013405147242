<template>
  <div class="z-index">
    <v-navigation-drawer
      width="280"
      v-model="drawer"
      :mini-variant.sync="mini"
      fixed
      app
    >
      <v-list-item @click="$router.push('/')" class="nav-bar primary">
        <v-list-item-avatar size="25" tile>
          <v-img src="https://uploaddeimagens.com.br/images/004/445/917/full/logo__1_-removebg-preview-removebg-preview.png?1682619908" id="img_logo"></v-img>
        </v-list-item-avatar>
        <v-list-item-title color="white" class="nav-bar-title">
          {{ title }}
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-group
          :value="true"
          v-for="level1 in items"
          :key="level1.id"
          v-model="level1.active"
          :prepend-icon="level1.icon"
          no-action
        >
          <template v-slot:activator>
            <!-- nivel 1 -->
            <v-list-item-title>{{ level1.title }}</v-list-item-title>
          </template>
          <template v-for="level2 in level1.submenu">
            <v-list-item
              :key="level2.id"
              v-if="level2.to"
              @click="router(level2.to)"
              v-model="level2.active"
            >
              <v-list-item-title>{{ level2.title }}</v-list-item-title>
            </v-list-item>

            <v-list-group
              :value="true"
              v-if="level2.title && !level2.to"
              :key="level2.id"
              v-model="level2.active"
              @click="router(level2.to)"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ level2.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <template v-for="level3 in level2">
                <v-list-item
                  v-if="level3.to"
                  v-model="level3.active"
                  :key="level3.id"
                  @click="router(level3.to)"
                >
                  <v-list-item-title>{{ level3.title }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar dark class="header primary" app  :style="'z-index: 40 !important;'">
      <v-app-bar-nav-icon color="white" @click="openMenu"></v-app-bar-nav-icon>
      <!-- <v-btn icon>
        <v-icon color="white">mdi-magnify</v-icon>
      </v-btn> -->
      

      <v-toolbar-title color="white">{{ $route.meta.title }}</v-toolbar-title>
      <v-col class="text-right">
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar color="primary" size="35">
                <img src="@/assets/images/users/1.jpg" />
              </v-avatar>
            </v-btn>
          </template>
          <v-card   :style="'z-index: 40 !important;'">
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="primary">
                  <img src="@/assets/images/users/1.jpg" />
                </v-avatar>
                <h3 class="mt-4 mb-4">{{ user?.USR_DESCRICAO }}</h3>
                <h6 class="mt-4 mb-4">Versão: {{ version }}</h6>

               
                <v-btn block height="50px" class="mb-n3" text @click="sair()">
                  Sair
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </v-col>
    </v-app-bar>
  </div>
</template>

<script>
const env = require("@/config/env.json");
export default {
  name: "NavBar",
  data: () => ({
    title: env.title,
    whatsappStatus: "Buscando...",
    user: "",
    mini: false,
    drawer: true,
    isMobile: true,
    items: [],
    version: env.version,
    credenciais: {}
  }),
  created() {
    this.user = JSON.parse(this.$cript.decryptData(this.$cookies.get("user")));
    this.mountMenu();
    this.verifyMobile();
  },

  methods: {
    mountMenu() {
      this.items = [
      {
          title: "Bens",
          icon: "mdi-home-city",
          submenu: [
            {
              title: "Listar Bens",
              icon: "mdi-cellphone-link",
              to: "/bens",
            },
          ],
        },
        {
          title: "Clientes",
          icon: "mdi-account-group",
          submenu: [
            {
              title: "Listar Clientes",
              icon: "mdi-cellphone-link",
              to: "/cliente",
            },
          ],
        },
        {
          title: "Contratos",
          icon: "mdi-file-document-edit-outline",
          submenu: [
            {
              title: "Listar Contratos",
              icon: "mdi-cellphone-link",
              to: "/contratos",
            },
          ],
        },
        {
          title: "INCC",
          icon: "mdi-counter",
          submenu: [
            { title: "Listar INCC", icon: "mdi-cellphone-link", to: "/INCC" },
          ],
        },
        {
          title: "Obras",
          icon: "mdi-beehive-outline",
          submenu: [
            { title: "Listar Obras", icon: "mdi-cellphone-link", to: "/obras" },
          ],
        },
        {
          title: "Pagamentos",
          icon: "mdi-cash-multiple",
          submenu: [
            { title: "Listar Pagamentos", icon: "mdi-cash-multiple", to: "/pagamentos" },
          ],
        },
      ];
      if(this.user.USR_TIPO === 1 || this.user.USR_TIPO === 3){
        this.items.push({
          title: "Usuários",
          icon: "mdi-account-multiple",
          submenu: [
            {
              title: "Listar Usuários",
              icon: "mdi-cellphone-link",
              to: "/usuarios",
            },
          ],
        },
        {
          title: "Beneficiário",
          icon: "mdi-cog",
          submenu: [
            {
              title: "Alterar Dados",
              icon: "mdi-cellphone-link",
              to: "/beneficiario",
            },
          ],
        });
      }
    },

    verifyMobile() {
      screen.width <= 600 ? (this.isMobile = true) : (this.isMobile = false);
      this.openMenu();
    },
    router(req) {
      if (req && this.$route.path != req) {
        this.clearCache();
        this.$router.push(req);
        this.openMenu();
      }
    },
    clearCache() {
      sessionStorage.clear();
    },
    openMenu() {
      if (this.isMobile == true) {
        this.mini = false;
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
      }
    },
    sair() {
      this.$store.dispatch("logout");
      this.clearCache();
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.nav-bar {
  padding: 8px 16px;
  font-size: 0.875rem;
  height: 60px !important;
}
.nav-bar-title {
  color: white !important;
}
.nav-bar-text {
  color: #000;
}
.nav-bar-icon {
  color: #000;
}

.header {
  position: fixed;
  height: 60px !important;

  z-index: 41;
  outline: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 1vh;
  height: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
</style>